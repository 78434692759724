<template>
  <b-container fluid>
    <b-row class="justify-content-center my-2">
      <b-card class="custom-card flex-grow-1 my-4 p-5 box-termo ">
        <h5>
          A solicitação de Afastamento Temporário deverá ser realizada através
          deste portal. A análise da solicitação terá um prazo de 45 (quarenta e
          cinco) dias.
        </h5>
        <h5 class="mt-5">
          A solicitação poderá ser negada em caso de falta de informações ou
          documentação e é de responsabilidade do cooperado, informar seus
          pacientes em caso de afastamento.O médico cooperado que tenha a sua
          solicitação de afastamento negada, poderá ingressar, a qualquer
          momento, com novo pedido dentro do prazo 30 dias a partir da data do
          início do evento, desde que sane a carência de informações ou
          documentação apontada pela Divisão de Relacionamento com o Cooperado
          da Cooperativa.
        </h5>
        <h5 class="mt-5">
          O afastamento temporário pode ser requerido nas seguintes hipóteses:
        </h5>
        <h5 class="font-weight-bold mt-5">
          Realização de cursos de aperfeiçoamento ou especialização
          profissional.
        </h5>
        <h5>
          Documentação:
        </h5>
        <h5>
          <ul>
            <li>
              Comprovante de matrícula no curso e/ou documento equivalente;
            </li>
            <li>
              Programa do curso com a estimativa de prazo para a conclusão;
            </li>
            <li>
              Poderá ser solicitado documentação adicional, caso seja
              necessário.
            </li>
          </ul>
        </h5>
        <h5 class="mt-5">
          Ao final do Afastamento Temporário, poderá ser solicitado certificado
          e/ou diploma comprovando a realização do curso.
        </h5>
        <h5 class="mt-5">
          A solicitação deverá ser feita no prazo de até 30 dias úteis antes de
          iniciar o curso, indicando o período que ficará afastado.
        </h5>
        <h5 class="font-weight-bold mt-5">
          Acidente ou doença.
        </h5>
        <h5>
          Documentação:
        </h5>
        <h5>
          <ul>
            <li>
              Atestado médico, neste deve constar com período de afastamento, comprovando a real necessidade da medida;
            </li>
            <li>
              Caso necessário, poderá ser solicitado a realização de perícia
              médica.
            </li>
          </ul>
        </h5>
        <h5 class="mt-5">
          Não será deferido o pedido de Afastamento Temporário por motivo de
          acidentes pessoais decorrente:
        </h5>
        <h5>
          -> Uso de material nuclear para quaisquer fins;
        </h5>
        <h5>
          -> Atos ou operações de guerra, declarada ou não;
        </h5>
        <h5>
          -> Competição em veículos, inclusive treinos preparatórios;
        </h5>
        <h5>
          -> Alterações mentais decorrentes do uso de substâncias tóxicas;
        </h5>
        <h5>
          -> Acidentes naturais como furacões, terremotos, entre outros.
        </h5>
        <h5 class="mt-5">
          O prazo para requerer o afastamento à cooperativa é de, no máximo, 30
          dias a partir da data do início do evento, podendo ser prorrogado de
          acordo com o Art. 121 do Regimento Interno.
        </h5>
        <h5 class="font-weight-bold mt-5">Licença maternidade.</h5>
        <h5>
          Documentação:
        </h5>
        <h5>
          <ul>
            <li>
              Atestado médico;
            </li>
            <li>
              Declaração da maternidade na qual ocorreu o nascimento.
            </li>
          </ul>
        </h5>
        <h5 class="mt-5">
          Será concedido pelo prazo máximo de até 6 (seis) meses, contados da
          data de deferimento.
        </h5>
        <h5 class="mt-5">
          A cooperada deverá requerer o afastamento, no prazo máximo de 30 dias
          a partir da data do nascimento do(a) filho(a).
        </h5>
        <h5 class="font-weight-bold mt-5">Licença paternidade.</h5>
        <h5>
          Documentação:
        </h5>
        <h5>
          <ul>
            <li>
              Atestado médico e/ou declaração da maternidade na qual ocorreu o nascimento.
            </li>
          </ul>
        </h5>
        <h5 class="mt-5">
          Será concedido pelo prazo máximo de até 5 dias (corridos), contados da data de deferimento. 
        </h5>
        <h5 class="mt-5">
          O cooperado deverá requerer o afastamento à cooperativa, no prazo máximo de 30 dias
          a partir da data do nascimento do(a) filho(a).
        </h5>
        <h5 class="font-weight-bold mt-5">
          Exercício de cargos ou funções públicas.
        </h5>
        <h5>Documentação:</h5>
        <h5>
          <ul>
            <li>
              Documento que comprove a assunção do cargo/função;
            </li>
            <li>
              Descrição de atribuições;
            </li>
            <li>Declaração, assinada pela entidade empregadora.</li>
          </ul>
        </h5>
        <h5 class="mt-5">
          Serão considerados motivos funcionais:
        </h5>
        <h5>
          -> Cargos ocupados no Sistema Unimed: presidente, vice presidente,
          superintendente e diretor técnico de serviço próprio;
        </h5>
        <h5>
          -> Cargos Públicos: Presidente da República, Governador, Prefeito, ou
          Vice, Senador, Deputado Federal ou Estadual ou Vereador;
        </h5>
        <h5>
          -> Diretor Geral ou Diretor Técnico de Hospital, Secretários de Estado
          ou Município, e Superintendentes da Secretaria Estadual ou Municipal
          de Saúde.
        </h5>
        <h5 class="mt-5">
          O cooperado deverá requerer o afastamento à cooperativa, no prazo
          máximo de 30 dias a partir da sua nomeação, podendo ser prorrogado de
          acordo com o Art 121 do Regimento Interno.
        </h5>
        <h5 class="mt-5">
          Os cooperados afastados por motivos funcionais poderão, mediante livre
          opção, continuar a desferir atendimentos médicos no âmbito da
          Cooperativa. Com exceção do Afastamento Temporário em razão de licença
          maternidade, nas demais hipóteses previstas acima, o cooperado poderá
          ficar afastado por, no máximo, 2 (dois) anos, podendo ser renovado,
          uma única vez, por igual período.
        </h5>
        <h5 class="mt-5">
          Durante o período de Afastamento Temporário é defeso ao médico
          cooperado afastado o exercício de qualquer atividade médica ou
          societária no âmbito da Cooperativa ou fora dela, sob pena de ser
          revogado, ex officio, o afastamento pelo Conselho de Administração.
        </h5>
        <h5 class="my-5">
          Outros casos que demandem o afastamento temporário que não estejam
          previstos no Regimento serão tratados pela Divisão de Relacionamento
          com o Cooperado, sendo o deferimento da solicitação condicionado ao
          referendo do Conselho de Administração.
        </h5>
      </b-card>
    </b-row>

    <AbsenceAddModal />
    <AbsenceDelayAddModal />
    <AbsenceAnticipationAddModal />

    <div>
      <b-row class="mt-5">
        <b-button
          class="my-2 mr-2"
          variant="primary"
          v-b-modal:add-absence-modal
        >
          Solicitar afastamento
        </b-button>

        <b-button
          class="m-2"
          variant="primary"
          v-b-modal:add-delay-absence-modal
        >
          Solicitar adiamento
        </b-button>

        <b-button
          class="m-2"
          variant="primary"
          v-b-modal:add-anticipation-absence-modal
        >
          Solicitar antecipação do retorno
        </b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import AbsenceAddModal from "../form/AbsenceAddModal.vue";
import AbsenceDelayAddModal from "../form/AbsenceDelayAddModal.vue";
import AbsenceAnticipationAddModal from "../form/AbsenceAnticipationAddModal.vue";

export default {
  name: "absence-informations",
  components: {
    AbsenceAddModal,
    AbsenceDelayAddModal,
    AbsenceAnticipationAddModal,
  },
};
</script>

<style lang="scss" scoped>
.marker {
  text-align: left;
  padding: 1rem;
}

.box-termo {
  padding: 1rem;
  max-height: 45rem;
  min-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
